





// To improve CLS, we only use css to hide the children on initial render
// and then use JS to hide the children once the component is mounted
import { defineComponent, ref, onMounted } from '@nuxtjs/composition-api';
import useWindowResize from '~/hooks/useResizeWindow';

export default defineComponent({
  setup() {
    const { isDesktop } = useWindowResize();
    const isMounted = ref(false);

    onMounted(() => {
      isMounted.value = true;
    });

    return {
      isMounted,
      isDesktop
    };
  }
});
