






























import { defineComponent, ref, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    itemsPerView: {
      type: Number,
      default: 1
    }
  },
  setup(props, context) {
    const currentViewIndex = ref(0);

    const itemsCount = computed(() => {
      const slots = context.slots.default();
      return slots.length;
    });

    const totalViews = computed(() => {
      const itemsPerView = props.itemsPerView;
      return Math.ceil(itemsCount.value / itemsPerView);
    });

    const containerWidthPercentage = computed(() => {
      const itemsPerView = props.itemsPerView;
      const viewsCount = itemsCount.value / itemsPerView;
      return `${viewsCount * 100}%`;
    });

    const currentViewTranslationX = computed(() => {
      const itemsPerView = props.itemsPerView;
      const percentageTakenByOneItem = 100 / itemsCount.value;
      let amountOfItemsToSkip;
      if (
        currentViewIndex.value !== 0 &&
        currentViewIndex.value >= totalViews.value - 1
      ) {
        amountOfItemsToSkip =
          itemsCount.value - currentViewIndex.value * itemsPerView;
      } else {
        amountOfItemsToSkip = currentViewIndex.value * itemsPerView;
      }
      return `-${amountOfItemsToSkip * percentageTakenByOneItem}%`;
    });

    const isFirstView = computed(() => {
      return currentViewIndex.value === 0;
    });

    const isLastView = computed(() => {
      return currentViewIndex.value === totalViews.value - 1;
    });

    const handleMoveRight = () => {
      if (currentViewIndex.value < totalViews.value - 1) {
        currentViewIndex.value++;
      }
    };

    const handleMoveLeft = () => {
      if (currentViewIndex.value > 0) {
        currentViewIndex.value--;
      }
    };

    return {
      containerWidthPercentage,
      currentViewTranslationX,
      isLastView,
      isFirstView,
      itemsCount,
      handleMoveRight,
      handleMoveLeft
    };
  }
});
