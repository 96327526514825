import { render, staticRenderFns } from "./KECarousel.vue?vue&type=template&id=7bfafe88&scoped=true&"
import script from "./KECarousel.vue?vue&type=script&lang=ts&"
export * from "./KECarousel.vue?vue&type=script&lang=ts&"
import style0 from "./KECarousel.vue?vue&type=style&index=0&id=7bfafe88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bfafe88",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsNavigationButton: require('/var/www/packages/theme/components/atoms/NavigationButton.vue').default})
